import React, { useState } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { Menu, X, ArrowRight, Code, Palette, Globe, ChevronRight, Mail, Phone, MapPin } from 'lucide-react';

const HomePage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { scrollYProgress } = useScroll();
  const opacity = useTransform(scrollYProgress, [0, 0.2], [1, 0]);
  
  const navItems = ['Services', 'Work', 'Process', 'Contact'];
  
  const projects = [
    { title: 'Fine Dining Experience', type: 'Restaurant', description: 'Modern web presence for an upscale dining establishment' },
    { title: 'Boutique Hotel', type: 'Hospitality', description: 'Luxury hotel booking and showcase platform' },
    { title: 'Tech Startup', type: 'SaaS', description: 'Landing page and product showcase for B2B SaaS' }
  ];

  const services = [
    { icon: <Code className="w-6 h-6" />, title: 'Web Development', description: 'Custom websites built with React, TypeScript, and modern tools' },
    { icon: <Palette className="w-6 h-6" />, title: 'UI/UX Design', description: 'Beautiful, intuitive interfaces that convert visitors' },
    { icon: <Globe className="w-6 h-6" />, title: 'Digital Presence', description: 'Full-stack solutions for your online business needs' }
  ];

  const processSteps = [
    { title: 'Discovery', description: 'Understanding your business goals and requirements' },
    { title: 'Strategy', description: 'Developing a tailored approach for your digital presence' },
    { title: 'Design', description: 'Creating beautiful, functional interfaces' },
    { title: 'Development', description: 'Building with modern, scalable technologies' },
    { title: 'Launch', description: 'Deploying and optimizing your digital solution' }
  ];

  return (
    <div className="min-h-screen bg-white">
      {/* Navigation */}
      <nav className="fixed w-full bg-white/80 backdrop-blur-md z-50 border-b border-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="text-xl font-bold tracking-tight"
            >
              <span className="font-black">its</span>
              <span className="font-light">ouput</span>
            </motion.div>

            <div className="hidden md:flex space-x-8">
              {navItems.map((item) => (
                <motion.a
                  key={item}
                  href={`#${item.toLowerCase()}`}
                  className="text-gray-600 hover:text-black transition-colors"
                  whileHover={{ y: -2 }}
                >
                  {item}
                </motion.a>
              ))}
            </div>

            <div className="md:hidden">
              <button 
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="p-2"
              >
                {isMenuOpen ? <X /> : <Menu />}
              </button>
            </div>
          </div>
        </div>

        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            className="md:hidden bg-white border-b border-gray-100"
          >
            {navItems.map((item) => (
              <a
                key={item}
                href={`#${item.toLowerCase()}`}
                className="block px-4 py-2 text-gray-600 hover:text-black hover:bg-gray-50"
                onClick={() => setIsMenuOpen(false)}
              >
                {item}
              </a>
            ))}
          </motion.div>
        )}
      </nav>

      {/* Hero Section */}
      <motion.section
        style={{ opacity }}
        className="min-h-screen flex items-center pt-16 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto"
      >
        <div className="max-w-3xl">
          <motion.h1
            initial={{ y: 20 }}
            animate={{ y: 0 }}
            className="text-6xl font-bold leading-tight mb-6"
          >
            Crafting digital <br />
            experiences that <br />
            <span className="text-gray-400">leave an impact</span>
          </motion.h1>
          <motion.p
            initial={{ y: 20 }}
            animate={{ y: 0 }}
            className="text-xl text-gray-600 mb-8 max-w-2xl"
          >
            We transform your vision into exceptional digital experiences using cutting-edge technology and thoughtful design.
          </motion.p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-black text-white px-6 py-3 rounded-lg flex items-center gap-2 text-lg"
          >
            Start your project <ArrowRight className="w-5 h-5" />
          </motion.button>
        </div>
      </motion.section>

      {/* Services Section */}
      <section id="services" className="py-32 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mb-16"
        >
          <h2 className="text-4xl font-bold mb-4">Our Services</h2>
          <p className="text-xl text-gray-600 max-w-2xl">
            We offer comprehensive digital solutions tailored to your unique needs.
          </p>
        </motion.div>
        
        <div className="grid md:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <motion.div
              key={service.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
              className="p-8 border border-gray-100 rounded-xl hover:border-gray-200 transition-colors group cursor-pointer"
            >
              <motion.div 
                className="mb-6 text-gray-400 group-hover:text-black transition-colors"
              >
                {service.icon}
              </motion.div>
              <h3 className="text-xl font-semibold mb-3">{service.title}</h3>
              <p className="text-gray-600 mb-4">{service.description}</p>
              <motion.div
                className="flex items-center text-sm font-medium"
                whileHover={{ x: 5 }}
              >
                Learn more <ChevronRight className="w-4 h-4 ml-1" />
              </motion.div>
            </motion.div>
          ))}
        </div>
      </section>

      {/* Process Section */}
      <section id="process" className="py-32 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto bg-gray-50">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mb-16"
        >
          <h2 className="text-4xl font-bold mb-4">Our Process</h2>
          <p className="text-xl text-gray-600 max-w-2xl">
            A systematic approach to delivering exceptional results.
          </p>
        </motion.div>

        <div className="grid md:grid-cols-5 gap-8">
          {processSteps.map((step, index) => (
            <motion.div
              key={step.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="relative"
            >
              <div className="mb-4 text-4xl font-bold text-gray-200">
                {(index + 1).toString().padStart(2, '0')}
              </div>
              <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
              <p className="text-gray-600">{step.description}</p>
            </motion.div>
          ))}
        </div>
      </section>

      {/* Projects Section */}
      <section id="work" className="py-32 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mb-16"
        >
          <h2 className="text-4xl font-bold mb-4">Selected Work</h2>
          <p className="text-xl text-gray-600 max-w-2xl">
            Showcasing our expertise across different industries.
          </p>
        </motion.div>

        <div className="grid md:grid-cols-3 gap-8">
          {projects.map((project, index) => (
            <motion.div
              key={project.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
              className="group cursor-pointer"
            >
              <div className="aspect-[4/3] bg-gray-100 rounded-lg mb-6 overflow-hidden">
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  className="w-full h-full bg-gray-200"
                />
              </div>
              <h3 className="text-xl font-semibold mb-2">{project.title}</h3>
              <p className="text-sm text-gray-500 mb-3">{project.type}</p>
              <p className="text-gray-600">{project.description}</p>
              <motion.div
                className="flex items-center text-sm font-medium mt-4"
                whileHover={{ x: 5 }}
              >
                View case study <ChevronRight className="w-4 h-4 ml-1" />
              </motion.div>
            </motion.div>
          ))}
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="py-32 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto bg-black text-white">
        <div className="grid md:grid-cols-2 gap-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <h2 className="text-4xl font-bold mb-4">Let's work together</h2>
            <p className="text-xl text-gray-400 mb-8">
              Have a project in mind? We'd love to hear about it. Drop us a line and we'll get back to you as soon as possible.
            </p>
            <div className="space-y-4">
              <a href="mailto:hello@itsouput.com" className="flex items-center text-gray-400 hover:text-white transition-colors">
                <Mail className="w-5 h-5 mr-3" />
                hello@itsouput.com
              </a>
              <a href="tel:+1234567890" className="flex items-center text-gray-400 hover:text-white transition-colors">
                <Phone className="w-5 h-5 mr-3" />
                +1 (234) 567-890
              </a>
              <div className="flex items-center text-gray-400">
                <MapPin className="w-5 h-5 mr-3" />
                Your Location
              </div>
            </div>
          </motion.div>

          <motion.form
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="space-y-6"
          >
            <div>
              <label className="block text-sm font-medium mb-2">Name</label>
              <input
                type="text"
                className="w-full px-4 py-3 bg-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-white/20"
                placeholder="Your name"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">Email</label>
              <input
                type="email"
                className="w-full px-4 py-3 bg-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-white/20"
                placeholder="your@email.com"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">Message</label>
              <textarea
                className="w-full px-4 py-3 bg-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-white/20 h-32"
                placeholder="Tell us about your project"
              />
            </div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="w-full bg-white text-black px-6 py-3 rounded-lg flex items-center justify-center gap-2 text-lg font-medium"
            >
              Send message <ArrowRight className="w-5 h-5" />
            </motion.button>
          </motion.form>
        </div>
      </section>

      {/* Footer */}
      <footer className="py-8 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto border-t border-gray-100">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-xl font-bold tracking-tight mb-4 md:mb-0">
            <span className="font-black">its</span>
            <span className="font-light">ouput</span>
          </div>
          <div className="text-sm text-gray-600">
            © {new Date().getFullYear()} itsouput. All rights reserved.
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;